/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Component,
  ElementRef,
  inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { NavbarService } from './navbar.service';
import { NotificationService } from '@shared/service/notification.service';
import {
  ThemeList,
  ThemeService,
} from 'src/app/shared/service/theme.service';
import { TranslateService } from '@ngx-translate/core';
import { SweetAlertService } from 'src/app/shared/service/sweet-alert.service';
import * as _ from 'lodash';
import * as $ from 'jquery';
import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import {
  animate,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Person } from '@shared/models/user.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LogOutModalComponent } from '../log-out-modal/log-out-modal.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  animations: [
    trigger('showHideNotification', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(100%)' }),
        animate(
          '300ms ease-in-out',
          style({ opacity: 1, transform: 'translateX(0)' }),
        ),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateX(100)' }),
        animate(
          '300ms ease-in-out',
          style({ opacity: 0, transform: 'translateX(100%)' }),
        ),
      ]),
    ]),
  ],
})
export class NavbarComponent implements OnInit, OnDestroy {
  navbarActive = false;
  photo: string | any;
  name: string | any;
  showUser = false;
  showLogo = false;
  fullWidth: number;
  title: string;
  isAdmin = false;
  translateSidebar: any;
  navbarTitle$: Observable<string>;
  page = 1;
  pageSize = 20;
  isLoading = false;

  themeList?: ThemeList;
  isNotificationUnread: boolean;
  @Input() profile?: Person;
  subscription: Subscription[] = [];

  notificationResult: any;
  notificationThisWeek: any;
  showNotificationToggle = false;
  period: 'this_week' | 'all_time' = 'this_week';
  unread: boolean | null;
  @ViewChild('navbarSize', { static: false })
  navbarSize: ElementRef;
  private modalService = inject(NgbModal);

  constructor(
    private router: Router,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private swal: SweetAlertService,
    private themeService: ThemeService,
    private navbar: NavbarService,
    private store: Store,
  ) {
    this.subscription.push(
      this.themeService.data.subscribe((theme) => {
        this.themeList = theme;
      }),
    );

    this.navbar.setNavbarTitle(
      this.navbar.navBarNameList.find((nav: any) => {
        const regex = new RegExp(`^${nav.url}`);
        return this.router.url.replace('/', '').match(regex);
      })?.name,
    );

    this.router.events.subscribe(() => {
      this.navbar.setNavbarTitle(
        this.navbar.navBarNameList.find((nav) => {
          const regex = new RegExp(`^${nav.url}`);
          return this.router.url.replace('/', '').match(regex);
        })?.name,
      );
    });
    this.navbarTitle$ = this.navbar.getNavbarTitle();
    const subs =
      this.notificationService.fetchNotification$.subscribe(() => {
        this.fetchNotification();
      });
    this.subscription.push(subs);
    this.subscribeUpdateNotification();
  }

  ngOnInit(): void {
    this.photo = localStorage.getItem('photoUrl');
    this.name = localStorage.getItem('name');
    this.isAdmin = localStorage.getItem('role_name') === 'Admin';
    this.showUser = true;
    this.fullWidth = document.documentElement.clientWidth;
    this.showLogo = this.fullWidth < 992;
    this.isNotificationUnread = this.store.selectSnapshot(
      (store) => store?.auth?.user_info.have_unread_notification,
    );
    this.getNotification();
    this.translateSidebar = this.translate;
    $('#menu-toggle').click((e) => {
      e.preventDefault();
      $('#wrapper').toggleClass('toggled');
    });
    const subscription = this.navbar.data.subscribe((res) => {
      setTimeout(() => {
        this.navbarActive = res;
      });
    });
    this.subscription.push(subscription);
  }

  ngOnDestroy(): void {
    this.subscription?.forEach((item) => {
      try {
        item.unsubscribe();
      } catch (e) {
        console.error(e);
      }
    });
  }

  filterUnreadNotification(unread: boolean | null) {
    this.unread = unread;
    this.fetchNotification();
  }

  fetchNotification() {
    this.page = 1;
    this.period = 'this_week';
    this.notificationThisWeek = null;
    this.notificationResult = null;
    this.getNotification();
  }

  getNotification(): void {
    this.isLoading = true;
    this.notificationService
      .getAllNotification({
        page: this.page,
        page_size: this.pageSize,
        order_by_date_only: true,
        period: this.period,
        unread: this.unread || '',
      })
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe(
        (res) => {
          if (this.period === 'this_week') {
            const oldResult =
              this.notificationThisWeek?.results || [];
            this.notificationThisWeek = res;
            this.notificationThisWeek.results = oldResult.concat(
              res.results,
            );
            if (!res.next) {
              this.period = 'all_time';
              this.page = 1;
              this.getNotification();
            } else {
              this.page++;
            }
          } else {
            const oldResult = this.notificationResult?.results || [];
            this.notificationResult = res;
            this.notificationResult.results = oldResult.concat(
              res.results,
            );
            this.page++;
          }
        },
        (err) => {
          this.swal.toastNotification({
            type: 'error',
            content: err.status,
          });
        },
      );
  }

  loadMoreNotification(): void {
    const haveNext =
      this.period === 'this_week'
        ? this.notificationThisWeek.next
        : this.notificationResult.next;
    if (haveNext && !this.isLoading) {
      this.getNotification();
    }
  }

  openNotification(): void {
    this.showNotificationToggle = !this.showNotificationToggle;
  }

  closeNotification(): void {
    this.showNotificationToggle = false;
  }

  markAllAsRead(): void {
    const subscription = this.notificationService
      .markAllAsRead()
      .subscribe(() => {
        this.isNotificationUnread = false;
        if (this.unread) {
          this.filterUnreadNotification(true);
        } else {
          this.notificationThisWeek.results.forEach(
            (notification: any) => {
              notification.read = true;
            },
          );
          this.notificationResult.results.forEach(
            (notification: any) => {
              notification.read = true;
            },
          );
        }
      });
    this.subscription.push(subscription);
  }

  // Notification
  clickNotification(notification: any): void {
    const subscription = this.notificationService
      .updateIsRead(notification.id)
      .subscribe(
        () => {
          this.markAsRead(notification, this.notificationResult);
          this.markAsRead(notification, this.notificationThisWeek);
          this.notificationRouter(notification);
        },
        (err) => {
          this.swal.toastNotification({
            type: 'error',
            content: err.status,
          });
        },
      );
    this.subscription.push(subscription);
  }

  markAsRead(notification: any, notificationObj: any) {
    if (!notificationObj) return;

    const index = _.findIndex(
      notificationObj.results,
      (n: any) => n.id === notification.id,
    );
    if (
      index !== -1 &&
      notificationObj.results[index].read === false
    ) {
      notificationObj.results[index].read = true;
      notificationObj.unread_count--;
    }
  }

  notificationRouter(notification: any): void {
    this.closeNotification();
    if (
      notification.notification.source_content_type_name === 'memo'
    ) {
      if (notification.notification.action === 'memo_pending') {
        return this.navigateToPage(
          '/pending-approval/',
          notification.notification.source_id,
        );
      } else {
        return this.navigateToPage(
          '/memos/preview/',
          notification.notification.source_id,
        );
      }
    } else if (
      notification.notification.source_content_type_name ===
      'announcement'
    ) {
      const status =
        notification.notification.action === 'acked'
          ? 'acked'
          : 'waiting';
      // update notification status for next time route
      if (notification.notification.action === 'waiting_no_ack') {
        notification.notification.action = 'acked';
      }
      this.router.navigate(
        [
          '/announcement/',
          notification.notification.source_id,
          'detail',
        ],
        {
          queryParams: { status },
        },
      );
    } else if (
      notification.notification.source_content_type_name ===
      'newsfeedpost'
    ) {
      this.navigateToPage(
        '/news-feed/',
        notification.notification.source_id,
      );
    }
  }

  subscribeUpdateNotification() {
    const subs =
      this.notificationService.updateNotification$.subscribe(
        (action) => {
          action(this.notificationThisWeek.results);
          action(this.notificationResult.results);
        },
      );
    this.subscription.push(subs);
  }

  navigateToPage(url: string, id: number): void {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => {
        this.router.navigate([url + id]);
      });
  }

  logOut() {
    this.modalService.open(LogOutModalComponent, {
      centered: true,
      size: 'size-smax56',
      backdrop: 'static',
      windowClass: 'background-blur-logout',
    });
  }

  navigateToProfile(): void {
    this.router.navigate(['/', 'profile-detail', 'detail']);
  }
}
