import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import {
  ClearMemoPasswordPopup,
  ShowMemoPasswordPopup,
} from '../../store/memo/memo.actions';

@Injectable()
export class MemoPasswordInterceptor implements HttpInterceptor {
  constructor(private store: Store) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> | any {
    return next.handle(request).pipe(
      catchError((err: any): any => {
        if (
          err.status === 403 &&
          ['REQT_INVALID_PASSWORD', 'INVALID_PASSWORD'].includes(
            err.error.detail,
          ) &&
          request.url.includes('memos')
        ) {
          const ids = request.url
            .match(/\/(\d+)+\/?/g)
            ?.map((id) => id.replace(/\//g, ''))
            ?.pop();
          this.store.dispatch(
            new ShowMemoPasswordPopup(
              err.error.detail === 'REQT_INVALID_PASSWORD',
              ids ? +ids : null,
            ),
          );
          return this.store
            .select((state) => {
              return state.memoPasswordPopUp;
            })
            .pipe(
              filter((payload) => !payload.show),
              take(1),
              switchMap((payload): any => {
                if (payload.password) {
                  this.store.dispatch(new ClearMemoPasswordPopup());
                  return this.intercept(
                    this.addPasswordHeader(request, payload.password),
                    next,
                  );
                } else {
                  return throwError(() => err);
                }
              }),
            );
        } else {
          return throwError(() => err);
        }
      }),
    );
  }

  private addPasswordHeader(
    request: HttpRequest<any>,
    password: string,
  ) {
    return request.clone({
      setHeaders: {
        'Memo-Access-Key': password,
        'Memo-Save-Log': 'True',
      },
    });
  }
}
