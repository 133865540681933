import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LaddaModule } from 'angular2-ladda';
import { CustomModalBodyDirective } from '../modal-confirmation/modal-confirmation.component';
import { ModalConfirmationModule } from '../modal-confirmation/modal-confirmation.module';

@Component({
  selector: 'app-modal-confirmation-std4-skin',
  standalone: true,
  imports: [
    CommonModule,
    LaddaModule,
    ModalConfirmationModule,
    TranslateModule,
  ],
  templateUrl: './modal-confirmation-std4-skin.component.html',
  styleUrls: ['./modal-confirmation-std4-skin.component.scss'],
  providers: [CustomModalBodyDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalConfirmationStd4SkinComponent {
  @Input() dismissText?: string;
  @Input() header?: string;
  @Input() isSubmitDisabled = false;
  @Input() isLoading = false;
  @Input() disabled = false;
  @Input() submitText?: string;
  @Input() subtitle?: string;
  @Input() subtitleSecondLine?: string;
  @Input() subtitleSecondLineCustomClass?: string;
  @Input() title?: string;
  @Input() titleSecondLine?: string;
  @Input() bodyNotDesignDownload? = false;
  @Input() showDismissButton = true;
  @Input() showSubmitButton = true;
  @Input() headerCloseButton = false;
  @Input() headerIconPath?: string;
  @Output() dismiss = new EventEmitter<void>();
  @Output() submitted = new EventEmitter<void>();
  @ContentChild(TemplateRef)
  imageRef: TemplateRef<unknown>;
  constructor() {}

  onDismissClick(): void {
    this.dismiss.emit();
  }

  onSubmitClick(): void {
    this.submitted.emit();
  }

  get actionBtnContainerClass(): string {
    if (!this.showDismissButton && this.showSubmitButton) {
      return 'justify-content-center my-3 mb-lg-4';
    }
    if (this.bodyNotDesignDownload) {
      return 'justify-content-end mt-4';
    }
    return 'justify-content-center my-3 my-lg-4';
  }
}
