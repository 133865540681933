import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Params } from '@angular/router';
import { UtilsService } from '@core/services/utils.service';
import {
  NgSelectComponent,
  NgSelectModule,
} from '@ng-select/ng-select';
import {
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { DropdownItem } from '@shared/models/common.model';
import { DropdownService } from '@shared/service/dropdown.service';
import * as _ from 'lodash';
import {
  asyncScheduler,
  debounceTime,
  distinctUntilChanged,
  filter,
  iif,
  map,
  Observable,
  of,
  startWith,
  Subject,
  Subscription,
  switchMap,
  tap,
} from 'rxjs';
import { ProfileDisplayComponent } from '../profile-display/profile-display.component';

@Component({
  selector: 'app-bubble-dropdown',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    ProfileDisplayComponent,
    TranslateModule,
  ],
  templateUrl: './bubble-dropdown.component.html',
  styleUrls: ['./bubble-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: BubbleDropdownComponent,
      multi: true,
    },
  ],
})
export class BubbleDropdownComponent<Data = any[], Response = Data[]>
  implements
    OnChanges,
    AfterViewInit,
    ControlValueAccessor,
    OnDestroy
{
  @Input() allSelectionText = 'COMMON.All';
  @Input() appendTo?: string;
  @Input() bindLabel: string;
  @Input() bindImage = 'photo';
  @Input() bindValue: string;
  @Input() clearable = true;
  @Input() choicesOnLessMinTerm: ChoicesOnLessMinTerm = 'default';
  @Input() dataGeneratorConfig?: DataGeneratorConfig<any>;
  @Input() defaultImage: string;
  @Input() disabled: boolean;
  @Input() imageConfig?: ImageConfig;
  @Input() isOpen: boolean;
  @Input() items: any[] = [];
  @Input() titleAllChoice: string;
  @Input() loading = false;
  @Input() minTermLength = 2;
  @Input() notFoundText = 'SELECT.No items found';
  @Input() showAllSelection = false;
  @Input() showCheckbox = true;
  @Input() showImage = false;
  @Input() showSearchInDropdown = false;
  @Input() showSelectedInBox = true;
  @Input() invalid = false;
  @Input() invalidItems: any[] = [];
  @Input() placeholder?: string;
  @Input() typeToSearchText?: string =
    'COMMON.Please enter 2 or more characters';
  @Output() add = new EventEmitter<any>();
  @Output() closed = new EventEmitter<any>();
  @Output() remove = new EventEmitter<any>();
  @Input() bindGroupLabel: string;
  @Input() groupBy: string;
  @Input() searchFn: (term: string, item: any) => boolean;
  @Input() showAllAsDefault = false;
  @ContentChild('selectedChoiceImgTemp') selectedChoiceImgTemp?:
    | TemplateRef<any>
    | undefined;
  @ContentChild('choiceImageTemp') choiceImageTemp?:
    | TemplateRef<any>
    | undefined;
  @ContentChild('choiceLabelTemp') choiceLabelTemp?:
    | TemplateRef<any>
    | undefined;
  @ContentChild('labelTemp') labelTemp?: TemplateRef<any> | undefined;
  @ViewChild(NgSelectComponent, { read: ElementRef })
  ngSelect?: ElementRef<HTMLElement>;
  @ViewChild('searchInput')
  searchInput?: ElementRef<HTMLInputElement>;

  dataGenerator?: (search: string) => Observable<Data[]>;
  dropdown$!: Observable<Data[]>;
  inDropdownSearch?: string | null;
  isOpened = false;
  searchInputContainer?: HTMLElement | null;
  search$?: Subject<string>;
  selected?: any;
  snapStartItems: Data[] = [];

  private cdRef = inject(ChangeDetectorRef);
  private compSubs: Subscription = new Subscription();
  private dropdownApi = inject(DropdownService);
  private dropdownSubs?: Subscription;
  private searchInputElement?: HTMLInputElement;
  private translate = inject(TranslateService);
  private utils = inject(UtilsService);
  private _onTouch: any;
  private _onChange: (val: any) => void = () => {};

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dataGeneratorConfig']) {
      const config = changes['dataGeneratorConfig']
        .currentValue as DataGeneratorConfig<any>;
      this.dataGenerator =
        config == null ? undefined : this.parseDataGenerator(config);
      this.initializeTypeahead();
    }
    if (changes['items']) {
      this.snapStartItems = this.items;
    }
  }

  ngOnDestroy(): void {
    this.compSubs.unsubscribe();
  }

  initializeTypeahead(): void {
    if (!this.dropdownSubs?.closed) {
      this.dropdownSubs?.unsubscribe();
    }
    const generator = this.dataGenerator;
    if (!generator) {
      this.search$?.unsubscribe();
      this.search$ = undefined;
      return;
    }
    this.search$ = new Subject<string>();
    const search$ = this.search$.pipe(
      distinctUntilChanged(),
      debounceTime(200),
    );
    let xhrSubs = new Subscription();
    this.dropdown$ = iif(
      () => this.showAllAsDefault,
      search$.pipe(startWith('')),
      search$.pipe(
        filter(
          (text): text is string =>
            text != null &&
            (this.choicesOnLessMinTerm !== 'default' || text !== ''),
        ),
      ),
    ).pipe(
      tap(() => (this.loading = true)),
      switchMap((text) => {
        if (!xhrSubs.closed) {
          xhrSubs.unsubscribe();
        }
        if (
          this.choicesOnLessMinTerm !== 'default' &&
          this.isLessThanMinTermLength(text)
        ) {
          if (
            this.choicesOnLessMinTerm === 'snapStart' &&
            !text.length
          ) {
            return of(this.snapStartItems);
          }
          return of([] as Data[]);
        }
        const subject$ = new Subject<Data[]>();
        xhrSubs = generator(text).subscribe(subject$);
        return subject$;
      }),
    );
    this.dropdownSubs = this.dropdown$.subscribe({
      next: (res) => {
        this.loading = false;
        if (!Array.isArray(res)) {
          throw new Error(
            'Data response has not been array. Use the accessor to convert type',
          );
        }
        this.items = res;
        this.cdRef.markForCheck();
      },
      error: (err) => {
        this.loading = false;
        console.error(err);
      },
    });
    this.compSubs.add(this.dropdownSubs);
  }

  ngAfterViewInit(): void {
    this.searchInputElement =
      this.ngSelect?.nativeElement.querySelector('.ng-input')
        ?.children[0] as HTMLInputElement | undefined;
    if (this.searchInputElement) {
      this.searchInputElement.placeholder =
        this.translate.instant('COMMON.Search') + '...';
    }
  }

  isLessThanMinTermLength(searchText: string): boolean {
    return (
      searchText == null || searchText.length < this.minTermLength
    );
  }

  getImage(item: any): string | undefined {
    return _.get(item, this.bindImage);
  }

  getImageSrc(item: { [k: string]: any }): string | undefined {
    if (!this.imageConfig?.bindSrc) {
      return;
    }
    return _.get(item, this.imageConfig.bindSrc);
  }

  getImageStyleClass(item: { [k: string]: any }): string | undefined {
    return (
      this.imageConfig?.bindImageStyleClass &&
      _.get(item, this.imageConfig.bindImageStyleClass)
    );
  }

  getImageType(item: { [k: string]: any }): string | undefined {
    if (!this.imageConfig?.bindType) {
      return 'person';
    }
    return _.get(item, this.imageConfig.bindType);
  }

  getLabel(item: any): string | undefined {
    return _.get(item, this.bindLabel);
  }

  getGroupLabel(item: any): string | undefined {
    return _.get(item, this.bindGroupLabel);
  }

  getGroupValue(groupKey: string, children: any[]) {
    return { context: children[0].context };
  }

  onDropdownOpen(): void {
    this.isOpened = true;
    asyncScheduler.schedule(() => {
      this.searchInput?.nativeElement.focus();
    });
  }

  onDropdownClose(event: Event): void {
    this.closed.emit(event);
    this.isOpened = false;
    const isTypeahead = !!this.dataGenerator;
    if (isTypeahead && this.choicesOnLessMinTerm !== 'default') {
      this.inDropdownSearch = null;
      this.items = [];
    }
  }

  onInDropdownSearch(val: string): void {
    if (!this.searchInputElement) {
      return;
    }
    this.searchInputElement.value = val;
    this.searchInputElement.dispatchEvent(new Event('input'));
  }

  onInDropdownSearchFocus() {
    if (!this.searchInputElement) {
      return;
    }
    this.searchInputElement.dispatchEvent(new Event('focus'));
  }

  onValueChange(val: any) {
    this._onChange(val);
  }

  parseDataGenerator(
    config: DataGeneratorConfig<any>,
  ): (search: string) => Observable<Data[]> {
    if (typeof config === 'function') {
      return config;
    } else if (typeof config === 'object') {
      return (search: string) => {
        const type = config.type;
        if (type == null) {
          throw new Error(
            'The type in dataGeneratorConfig is required',
          );
        }
        this.cdRef.markForCheck();
        return this.dropdownApi
          .getDropdown({
            type,
            ...this.utils.getValidQueryParams(config.params || {}, {
              nullable: true,
            }),
            [config.typingParamName || 'query']: search,
          })
          .pipe(
            map((res) => res[type]),
            map((res) =>
              config.transformer ? res.map(config.transformer) : res,
            ),
          );
      };
    } else {
      throw new Error('The type of dataGeneratorConfig is invalid');
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }

  toggleAllSelection(checked: boolean) {
    this.selected = checked
      ? this.items.map((item) =>
          this.bindValue ? item[this.bindValue] : item,
        )
      : [];
    this.onValueChange(this.selected);
  }

  writeValue(obj: any): void {
    this.selected = obj;
  }

  get isAllChecked() {
    return (
      this.selected &&
      this.selected.length > 0 &&
      this.selected.length === this.items.length
    );
  }
}

export interface DropdownGeneratorConfig<T = any> {
  params?: Params;
  transformer?: (items: DropdownItem<any>) => T;
  type: string;
  typingParamName?: string;
}

export type DataGeneratorConfig<T> =
  | DropdownGeneratorConfig<T>
  | ((search: string) => Observable<T[]>);

export interface ImageConfig {
  bindImageStyleClass?: string;
  bindType?: string;
  bindSrc?: string;
}

export type ChoicesOnLessMinTerm = 'default' | 'clear' | 'snapStart';
