import { nanoid } from 'nanoid';
import {
  Marker,
  MarkerIdentity,
} from '../components/upload-memos/pdf-signature-customizer/pdf-signature-customizer.model';

export const transformMarkerVersion1Into4 = (
  markers: Marker[],
): Marker[] => {
  markers.forEach((marker) => {
    const identityKeys = [
      'positions',
      'approved_date_positions',
      'comment_positions',
      'stamp_positions',
    ] as const;
    const identityGroupMap: { [id: string]: string } = {};
    identityKeys.forEach((key) => {
      const identities = marker[key];
      const nonIdIdentity: MarkerIdentity[] = [];
      const ungrouped: MarkerIdentity[] = [];
      identities?.forEach((identity) => {
        if (!identity.id) {
          nonIdIdentity.push(identity);
          return;
        }
        if (identity.group) {
          identityGroupMap[identity.id] = identity.group;
          delete identity.pageIdList;
          delete identity.pageList;
        } else {
          ungrouped.push(identity);
        }
      });
      const newGroup = nanoid(5);
      ungrouped.forEach((identity) => {
        if (!identity.id) return; // It should not happen because the filter above
        identity.group ||= identityGroupMap[identity.id] || newGroup;
        identity.pageIdList?.forEach((pageId) => {
          identityGroupMap[pageId.id] ||= newGroup;
        });
        delete identity.pageIdList;
        delete identity.pageList;
      });
      if (nonIdIdentity.length > 0) {
        console.error(
          'There is widgets with non-id identity, please check',
          nonIdIdentity,
        );
      }
    });
  });
  return markers;
};

export const getTransformedMarkerByVersion = (
  markers: Marker[],
  markerVersion: string,
): Marker[] => {
  const isOneToFour = !!markerVersion.match(/^1\..+$/);
  if (isOneToFour) {
    return transformMarkerVersion1Into4(markers);
  }
  return markers;
};
