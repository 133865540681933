<div class="d-none d-md-block mb-4">
  <h4 class="fs-base-l1 fs-base-md-u11 fw-bold mb-2">
    {{ 'LOGIN.LET-LOGIN' | translate }}
  </h4>
  <div class="text-gray-neutrals fs-base-l5 fs-base-md-u0">
    {{ 'LOGIN.SUB-TITLE' | translate }}
  </div>
</div>
<ng-container [ngSwitch]="mode">
  <ng-container *ngSwitchCase="0"
      [ngTemplateOutlet]="LoginForm"></ng-container>
  <ng-container *ngSwitchCase="1"
      [ngTemplateOutlet]="LoginADFS"></ng-container>
</ng-container>
<div *ngIf="loginAdfsEnable"
    class="mt-2 fs-l4 align-self-end text-gray-neutrals pointer"
    (click)="switchMode()">
  {{ (mode === 0 ? 'LOGIN.SIGN-IN-WITH-ADFS-ACCOUNT' : 'LOGIN.SIGN-IN-WITH-LOCAL-ACCOUNT') | translate }}
</div>
<div class="text-center pt-u0 d-none d-md-block version fs-l8 text-gray-roman-silver">v.{{ version || '0.0.0' }}</div>

<ng-template #LoginForm>
  <form #fg="ngForm"
      [formGroup]="loginForm"
      (ngSubmit)="login()"
      class="row login-form">
    <div class="col-12">
      <label class="d-flex align-items-center">
        <svg stroke="#1D1F2C"
            stroke-width="2"
            width="20"
            height="20"
            viewBox="0 0 24 24">
          <use href="assets/images/icons/people/person.svg#person"></use>
        </svg>
        <span class="ms-l8">{{ 'LOGIN.USERNAME' | translate }}</span>
        <span class="text-asterisk">*</span>
      </label>
      <input type="text"
          autocomplete="off"
          class="form-control"
          formControlName="username"
          [ngClass]="{'is-invalid': (fg.submitted && f.username.invalid)}"
          [placeholder]="'LOGIN.USERNAME' | translate">
      <div *ngIf="f.username.errors?.be_error"
          class="invalid-feedback">
        {{ f.username.errors.be_error[currentLang] }}
      </div>
    </div>
    <div class="col-12 mt-base-u8 mt-base-md-u0">
      <label class="d-flex align-items-center">
        <svg stroke="#1D1F2C"
            stroke-width="2"
            width="20"
            height="20"
            viewBox="0 0 24 24">
          <use href="assets/images/icons/securities/lock.svg#lock"></use>
        </svg>
        <span class="ms-l8">{{ 'LOGIN.PASSWORD' | translate }}</span>
        <span class="text-asterisk">*</span>
      </label>
      <app-password-input
          [ngClass]="{'is-invalid': (fg.submitted && f.password.invalid)}"
          formControlName="password"
          [placeholder]="'LOGIN.PASSWORD'|translate"
          [showInvalid]="fg.submitted">
      </app-password-input>
      <div *ngIf="remainingAttempts && remainingAttempts !== -999"
          class="invalid-feedback">
        {{ 'PROFILE.Incorrect attempt' | translate }}
        {{ remainingAttempts }}/{{ loginFailureLimit }}
      </div>
    </div>
    <div class="col-12 text-end">
      <span (click)="forgetPassword.emit()"
          class="text-theme pointer mt-2 fs-base-l2">
        {{ 'PROFILE.Forget Password' | translate }}?
      </span>
    </div>
    <div class="col-12 mt-base-u8 mt-md-4 pt-2">
      <button class="w-100 btn btn-std btn-h-res fs-base-l0 btn-std-prim"
          type="submit">
        {{ "LOGIN.LOGIN" | translate }}
      </button>
    </div>
    <div *appFeatureFlag="'thaid_login_enable'"
        class="col-12 mt-base-u8 mt-md-4 pt-2">
      <button (click)="loginThaiD()"
          class="w-100 btn btn-std btn-h-res fs-base-l0 btn-std-prim">
        {{ 'LOGIN.SIGN-IN-WITH-THAI-D' | translate }}
      </button>
    </div>
  </form>
</ng-template>

<ng-template #LoginADFS>
  <form #fg="ngForm"
      [formGroup]="adfsForm"
      (ngSubmit)="adfsLogin()"
      class="row login-form">
    <div class="col-12">
      <label class="d-flex align-items-center">
        <svg stroke="#1D1F2C"
            stroke-width="2"
            width="20"
            height="20"
            viewBox="0 0 24 24">
          <use href="assets/images/icons/people/person.svg#person"></use>
        </svg>
        <span class="ms-l8">{{ 'LOGIN.EMAIL' | translate }}</span>
        <span class="text-asterisk">*</span>
      </label>
      <input type="text"
          autocomplete="off"
          class="form-control"
          formControlName="email"
          [ngClass]="{'is-invalid': (fg.submitted && adfsForm.controls['email'].invalid)}"
          [placeholder]="'LOGIN.EMAIL' | translate">
    </div>
    <div class="col-12 mt-4 pt-2">
      <button class="w-100 btn btn-std btn-h-res fs-base-l0 btn-std-prim"
          type="submit">
        {{ "LOGIN.LOGIN" | translate }}
      </button>
    </div>
  </form>
</ng-template>

<ng-template #wrongPasswordAttempt
  let-modal>
  <app-modal-wrong-password-attempt
      [username]="f.username.value"
      [remainingAttempts]="remainingAttempts"
      (closePopup)="modal.close()"
      (forgetPassword)="modal.close(); forgetPassword.emit()">
  </app-modal-wrong-password-attempt>
</ng-template>