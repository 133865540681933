import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {
  LangChangeEvent,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { DropdownItem } from '@shared/models/common.model';
import { DropdownService } from '@shared/service/dropdown.service';
import { ReplaySubject, Subject, Subscription, take } from 'rxjs';
import { UtilsService } from 'src/app/core/services/utils.service';
import { BubbleDropdownComponent } from '../bubble-dropdown/bubble-dropdown.component';
import { ProfileDisplayComponent } from '@shared/components/profile-display/profile-display.component';
import { DepartmentNameWithMemberCount } from '@shared/models/department.model';
import { ThemeService } from '@shared/service/theme.service';

@Component({
  selector: 'app-department-bubble-dropdown',
  standalone: true,
  imports: [
    BubbleDropdownComponent,
    CommonModule,
    FormsModule,
    TranslateModule,
    ProfileDisplayComponent,
  ],
  templateUrl: './department-bubble-dropdown.component.html',
  styleUrls: ['./department-bubble-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DepartmentBubbleDropdownComponent,
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DepartmentBubbleDropdownComponent
  implements ControlValueAccessor, OnInit, OnDestroy
{
  @Input() appendTo?: string;
  @Input() invalid = false;
  @Input() isOpen: boolean;
  @Input() clearable = false;
  @Input() showAllChoice = true;
  @Input() showSearchInDropdown = true;
  @Input() showSelectedInBox = true;
  @Input() invalidItems: any[] = [];
  @Input() placeholder?: string;
  @Input() canUseCache = false;
  departmentChoices: DropdownItem<
    number | string,
    DepartmentNameWithMemberCount
  >[] = [];
  departmentImage = 'assets/images/icons/people/people.svg#people';
  isDeptFetching = false;
  isSelectedAll = false;
  language!: string;
  originalDeptChoices: DropdownItem<
    number,
    DepartmentNameWithMemberCount
  >[] = [];
  originalDeptChoiceValues: number[] = [];
  prevSelectedDepartment?: (number | string)[] | null;
  selectedDepartment?: (number | string)[] | null;

  private compSub = new Subscription();
  private cdRef = inject(ChangeDetectorRef);
  private dropdownApi = inject(DropdownService);
  private themeService = inject(ThemeService);
  private originalDeptChoiceValues$: Subject<number[]> =
    new ReplaySubject(1);
  private translate = inject(TranslateService);
  private util = inject(UtilsService);
  private _onTouch: () => void;
  private _onChange: (val: any) => void;

  constructor() {
    this.language = this.translate.currentLang;
    this.subscribeTranslate();
  }

  ngOnInit(): void {
    this.fetchDepartmentChoices();
  }

  ngOnDestroy(): void {
    this.compSub.unsubscribe();
  }

  emitCurrentSelected(): void {
    const emitValue =
      this.selectedDepartment &&
      this.selectedDepartment.filter((dept) => dept !== 'all');
    this._onChange(emitValue);
  }

  fetchDepartmentChoices(): void {
    this.isDeptFetching = true;
    const subs = this.dropdownApi
      .getAllDepartmentDropdown(this.canUseCache)
      .subscribe({
        next: (res) => {
          this.isDeptFetching = false;
          this.originalDeptChoices = res;
          this.originalDeptChoiceValues =
            this.originalDeptChoices.map(
              (choice) => choice.value,
            ) as number[];
          this.originalDeptChoiceValues$.next(
            this.originalDeptChoiceValues,
          );
          this.updateAllDepartmentCheckbox();
          this.departmentChoices = [...res];
          if (this.showAllChoice) {
            this.departmentChoices.unshift({
              label: '',
              value: 'all',
              context: {
                id: 0,
                photo: this.themeService.themeConfigs.logo_round,
                display: 'ทุกแผนก',
                display_en: 'All departments',
                member_count: 0,
              },
            });
          }
          this.cdRef.detectChanges();
        },
      });
    this.compSub.add(subs);
  }

  getIsSelectedAllDept(val: (number | string)[] | null): boolean {
    if (val == null || val.length === 0) {
      return false;
    }
    return this.util.isSameMemberArray(
      this.originalDeptChoiceValues,
      val,
    );
  }

  onDepartmentDropdownChange(val: (number | string)[] | null): void {
    this.prevSelectedDepartment =
      this.selectedDepartment == null
        ? this.selectedDepartment
        : [...this.selectedDepartment];
    this.selectedDepartment = val && [...val];
    this.updateAllDepartmentCheckbox();
    this.emitCurrentSelected();
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }

  /**
   * Public method to select all departments or unselect all.
   * @param val `true` to select all, `false` to unselect all
   */
  public selectAllDepartments(val = true): void {
    const subs = this.originalDeptChoiceValues$
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.setAllCheckbox(val);
          this.emitCurrentSelected();
        },
      });
    this.compSub.add(subs);
  }

  private setAllCheckbox(isAll: boolean): boolean {
    if (isAll) {
      this.selectedDepartment = [...this.originalDeptChoiceValues];
      if (this.showAllChoice) {
        this.selectedDepartment.push('all');
      }
    } else {
      this.selectedDepartment = [];
    }
    return (this.isSelectedAll = isAll);
  }

  writeValue(val: number[]): void {
    this.selectedDepartment = val && [...val];
    this.updateAllDepartmentCheckbox();
  }

  subscribeTranslate(): void {
    const subs = this.translate.onLangChange.subscribe({
      next: (event: LangChangeEvent) => {
        this.language = event.lang;
        this.cdRef.markForCheck();
      },
    });
    this.compSub.add(subs);
  }

  updateAllDepartmentCheckbox(): boolean {
    if (!this.selectedDepartment?.length) {
      this.isSelectedAll = false;
      return false;
    }
    const isExistAll = this.selectedDepartment.includes('all');
    const isPrevExistAll =
      !!this.prevSelectedDepartment?.includes('all');
    if (isPrevExistAll && !isExistAll) {
      return this.setAllCheckbox(false);
    } else if (!isPrevExistAll && isExistAll) {
      return this.setAllCheckbox(true);
    } else {
      const isAll = this.getIsSelectedAllDept(
        this.selectedDepartment,
      );
      if (isAll) {
        this.setAllCheckbox(true);
      } else {
        const allIndex = this.selectedDepartment.findIndex(
          (dept) => dept === 'all',
        );
        if (allIndex > -1) {
          this.selectedDepartment.splice(allIndex, 1);
        }
      }
      this.isSelectedAll = isAll;
      return isAll;
    }
  }

  searchFnDept(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return (
      item.context.display_en.toLocaleLowerCase().indexOf(term) >
        -1 ||
      item.context.display.toLocaleLowerCase().indexOf(term) > -1
    );
  }
}
